import * as pluralize from "pluralize";

/**
 * Pluralise text based on number
 *
 * @param num
 * @param text
 * @param textPlural
 * @returns
 */
export const plurable = (
  num: number,
  text: string,
  textPlural?: string
): string => {
  if (num == 1) return text;
  if (textPlural) return textPlural;
  return pluralize.plural(text);
};

/**
 * Convert PascalCase or camelCase to sentence
 * @param text
 * @returns
 */
export const caseToSentence = (text: string) => {
  return text.replace(/([A-Z])/g, " $1"); // insert a space before all caps
};

export const trimText = (text: string, max: number) => {
  if (text.length <= max) {
    return text;
  }
  return text.slice(0, max).trimEnd() + "...";
};
